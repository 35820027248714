import { setCookie, getCookie } from "@exp/scripts/helpers/cookies";
import { checkConsentSync } from "../integration/didomi";
const useObserver = ("IntersectionObserver" in window);
const trackingLabels = {
  vin: "Aov-Maisha",
  "vin-mittKok": "Aov-Mk-Maisha",
  leva: "leva-Maisha",
  mama: "mama-Maisha",
  damernasvarld: "damernasvarld-Maisha",
  skonahem: "skonahem-Maisha",
  amelia: "amelia-Maisha",
  alltomtradgard: "alltomtradgard-Maisha",
  teknikensvarld: "teknikensvarld-Maisha",
  resor: "alltomresor-Maisha",
  halsoliv: "halsoliv-Maisha"
};
export default function init(scope) {
  const newsletter = scope.getElementsByClassName("newsletter")[0];
  if (!newsletter) return;
  const formElement = newsletter.getElementsByTagName("form")[0];
  if (!formElement) return;
  window.ExpressenAnalytics = window.ExpressenAnalytics || [];
  formElement.addEventListener("submit", handleNewsLetterSignup);
  const observer = setupObserver();
  if (observer) {
    observer.observe(newsletter);
  }
  function setupObserver() {
    if (!useObserver) return;
    return new window.IntersectionObserver(viewPortUpdate, {
      rootMargin: "0px",
      threshold: 0.5
    });
    function viewPortUpdate(entries) {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          observer.unobserve(entry.target);
          window.ExpressenAnalytics.push(analytics => {
            const trackingLabel = getTrackingLabel();
            analytics.track.customPassive("event", "inview", trackingLabel);
          });
        }
      });
    }
  }
  function handleNewsLetterSignup(event) {
    event.preventDefault();
    const form = event.target;
    if (!form) return;
    const email = form.email && form.email.value;
    const secondaryNewsletter = form.secondaryNewsletter;
    const termsAgreed = form.termsAgreed && form.termsAgreed.checked;
    const fullName = window.Exp && window.Exp.config && window.Exp.config.fullName || "";
    const feedbackText = form.getElementsByClassName("feedback")[0];
    feedbackText.innerHTML = "";
    if (!termsAgreed) {
      const isAlltOmVin = window.Exp.config.brandName === "vin";
      feedbackText.innerHTML = isAlltOmVin ? "Du måste intyga att du är över 25 år och bekräfta att du har läst Bonniers personuppgiftspolicy och Vinkompassens personuppgiftspolicy." : "Du måste bekräfta att du läst Bonnier News personuppgiftspolicy";
      return;
    }
    if (!validEmail(email)) {
      feedbackText.innerHTML = "Den angivna e-postadressen är inte giltig. <br />Vänligen dubbelkolla stavningen.";
      return;
    }
    let endpoint = `${window.Exp.config.path}/newsletter-signup`;
    endpoint += `?email=${encodeURIComponent(email)}`;
    window.fetch(`${endpoint}`).then(response => {
      const responseSuccess = response.status && response.status >= 200 && response.status < 300;
      if (!responseSuccess) {
        throw new Error(`HTTP error, status = ${response.status}`);
      }
      return response.json();
    }).then(json => {
      if (json.voyado && json.voyado.error || json.adobe && json.adobe.error || json.ma && json.ma.error) {
        throw new Error(json);
      }
      const newsletterSignupContent = newsletter.getElementsByClassName("newsletter-signup")[0];
      const successMessageContent = newsletter.getElementsByClassName("newsletter-signup__success")[0];
      successMessageContent.innerHTML = `Tack, du är nu registrerad som prenumerant på ${fullName === "Leva & bo" ? "LEVA&BO" : fullName}s nyhetsbrev!`;
      newsletterSignupContent.classList.add("hidden");
      successMessageContent.classList.remove("hidden");
      if (checkConsentSync("marketing")) {
        const brandName = window.Exp.config.brandName;
        const cookieName = `newsletterSignup-${brandName}`;
        const newsletterSignupCookie = getCookie(cookieName);
        if (!newsletterSignupCookie) {
          setCookie(cookieName, brandName, {
            "max-age": 31556926
          });
        }
      }
      const isWidget = ("isWidget" in newsletter.dataset);
      const trackingLabel = getTrackingLabel(secondaryNewsletter, isWidget);
      window.ExpressenAnalytics.push(analytics => {
        analytics.track.customActive("email subscription", "subscribed", trackingLabel);
      });
    }).catch(() => {
      feedbackText.innerHTML = "Vi kan inte registrera e-postadressen just nu. <br />Försök igen om ett litet tag.";
    });
    form.email.value = "";
  }
  function validEmail(email) {
    return email && email.indexOf("@") > -1 && email.indexOf(".") > -1;
  }
  function getTrackingLabel(secondaryNewsletter, isWidget) {
    let trackingLabel = "";
    const isAlltOmVin = window.Exp.config.brandName === "vin";
    if (isAlltOmVin) {
      if (secondaryNewsletter && secondaryNewsletter.checked) {
        trackingLabel = trackingLabels["vin-mittKok"];
      } else {
        trackingLabel = trackingLabels.vin;
      }
    } else if (trackingLabels[window.Exp.config.brandName]) {
      trackingLabel = trackingLabels[window.Exp.config.brandName];
    } else {
      trackingLabel = `newsletter-tracking-not-implemented-for-${window.Exp.config.brandName}`;
    }
    trackingLabel += isWidget ? "-widget" : "";
    return trackingLabel;
  }
}